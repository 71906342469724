<template>
  <div>
    <bread-crumb
      :crumbData="['策略设置', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 50px; width: 40%"
    >
      <el-form-item label="策略名称：" prop="policyName">
        <el-input v-model="ruleForm.policyName"></el-input>
      </el-form-item>
      <el-form-item label="直接佣金：" prop="commission">
        <div style="display: flex">
          <el-input v-model="ruleForm.commission"></el-input>
          <span style="margin-left: 20px">%</span>
        </div>
      </el-form-item>
      <el-form-item label="消费返还：" prop="rebate">
        <div style="display: flex">
          <el-input v-model="ruleForm.rebate"></el-input>
          <span style="margin-left: 20px">%</span>
        </div>
      </el-form-item>
      <el-form-item label="二级佣金：" prop="secondCommission">
        <div style="display: flex">
          <el-input v-model="ruleForm.secondCommission"></el-input>
          <span style="margin-left: 20px">%</span>
        </div>
      </el-form-item>
      <el-form-item label="会员支付：" prop="memberPay">
        <div style="display: flex">
          <el-input v-model="ruleForm.memberPay"></el-input>
          <span style="margin-left: 20px">%</span>
        </div>
      </el-form-item>
      <el-form-item label="金币支付：" prop="godsPay">
        <el-radio-group v-model="ruleForm.godsPay">
          <el-radio :label="0">不支持</el-radio>
          <el-radio :label="1">支持</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="金币支付比例：" prop="memberPay">
        <div style="display: flex">
          <el-input v-model="ruleForm.goldRatio"></el-input>
          <span style="margin-left: 20px">%</span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      ruleForm: {
        policyName: "",
        commission: "",
        rebate: "",
        secondCommission: "",
        memberPay: "",
        godsPay: 0,
        goldRatio: "",
        commercialId: "",
      },
      rules: {
        policyName: [{ required: true, message: "必填字段", trigger: "blur" }],
        commission: [{ required: true, message: "必填字段", trigger: "blur" }],
        rebate: [{ required: true, message: "必填字段", trigger: "blur" }],
        secondCommission: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        memberPay: [{ required: true, message: "必填字段", trigger: "blur" }],
        godsPay: [{ required: true, message: "必填字段", trigger: "change" }],
      },
      id: "",
    };
  },
  mounted() {
    this.ruleForm.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$props.data) {
      const data = this.$props.data;
      console.log(data);
      this.id = data.id;
      this.ruleForm.policyName = data.policy_name;
      this.ruleForm.commission = data.barckage_one;
      this.ruleForm.rebate = data.consumption_rebate;
      this.ruleForm.secondCommission = data.barckage_two;
      this.ruleForm.memberPay = data.member_pay;
      this.ruleForm.godsPay = data.gold_pay;
      this.ruleForm.goldRatio = data.gold_ratio;
    }
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/system/policy_set/save", {
              id: this.id,
              policy_name: this.ruleForm.policyName,
              barckage_one: this.ruleForm.commission,
              consumption_rebate: this.ruleForm.rebate,
              barckage_two: this.ruleForm.secondCommission,
              member_pay: this.ruleForm.memberPay,
              gold_pay: this.ruleForm.godsPay,
              gold_ratio: this.ruleForm.goldRatio,
              commercial_id: this.ruleForm.commercialId,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
